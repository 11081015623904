import moment from 'moment';

jQuery( document ).ready(function($) {

    // Mobile Menu
    document.querySelector('.menu-toggle').addEventListener('click', function (e) {
        document.querySelector('.menu-header-menu-container').classList.toggle('active');
        e.currentTarget.classList.toggle('active');
    })

    // Homepage Hero Slider
    $('.homepage-hero-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 6000
    });

    // Player
    $( '.player button' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).find( 'i' ).toggle();

		const audios = $( '.player audio' );
		const audio = $( this ).next( 'audio' )[0];

		audios.each( function ( index, item ) {
			if ( $( item )[0] !== audio ) {
				$( item )[0].pause();
				$( item ).prev( 'button' ).find( '.fa-pause' ).css( 'display', 'none' );
				$( item ).prev( 'button' ).find( '.fa-play' ).css( 'display', 'inline-block' );
			}
		} );

		if ( audio.paused ) {
			audio.play();
		} else {
			audio.pause();
		}
	} );

	// Tour
    $.ajax( {
        url: 'https://rest.bandsintown.com/artists/Kristin%20Chenoweth/events?app_id=45PRESS_kristin-chenoweth',
        method: 'GET',
        dataType: 'json',
        xhrFields: {
            withCredentials: false
        },
        error: () => {
        },
        success: data => {
            const events = $( '#events' );
            let html = '';
            if ( data.length ) {
                for ( let event of data ) {
                    const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                    html += '<div class="event" data-bit-id="' + event.id + '">';
                    html += '<div class="side">';
                    html += '<p class="month">' + moment( event.datetime ).format( 'MMM' ) + '</p>';
                    html += '<p class="day-year">' + moment( event.datetime ).format( 'DD.YYYY' ) + '</p>';
                    html += '<a href="' + event.url + '" target="_blank" class="btn">Buy Tickets</a>';
                    html += '</div>';
                    html += '<div class="side">';
                    html += '<p class="venue">' + event.venue.name + '</p>';
                    html += '<p class="location">' + event_location + '</p>';
                    html += '</div>';
                    html += '</div>';
                }
                events.html( html );
            } else {
                events.html( 'No upcoming events.' );
            }
        }
    } );

    // Get youtube thumbnails
    if (document.querySelector('body').classList.contains('tax-video_cat')){
        const iframe = document.querySelectorAll('iframe')
        let counter = 1
        iframe.forEach(iframe => {
            const iframeSrc = iframe.getAttribute('src')
            const youtubeVideoId = iframeSrc.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop()
            const videoThumbnail = `<img src="//img.youtube.com/vi/${youtubeVideoId}/0.jpg">`
            document.querySelector(`.video-thumbnail-${counter}`).innerHTML = videoThumbnail
            counter++
        })
    }

});